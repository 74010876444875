import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Typography } from '@material-ui/core';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

export default class Download extends React.Component {
  
  
    render() {

        return (
<div>

  <div className="text-center bg-primary text-white p-2 mt-2 mb-3">
     <Typography variant="h6" style={{ color:'#fff' }}> <b>Download</b></Typography>
</div>
  <a href="https://pdf.drbrambedkarcollegeoflaw.com/pdf/3years-LLB.pdf" target="_blank" className="text-white" rel="noopener noreferrer">
                            <div className="d-flex download">
                         <div className="ps-2 p-1"><Typography variant="body2"> <b>Application For 3 Years</b></Typography></div>
                         <div className="ms-auto bg-dark"><PlayArrowIcon/></div>
                        </div>
                        </a>

   <a href="https://pdf.drbrambedkarcollegeoflaw.com/pdf/5years.pdf" target="_blank" className="text-white" rel="noopener noreferrer">
                            <div className="d-flex download mt-1">
                         <div className="ps-2 p-1"><Typography variant="body2"><b>Application For 5 Years</b></Typography></div>
                         <div className="ms-auto bg-dark"><PlayArrowIcon/></div>
                        </div>
                        </a>

<a href="https://pdf.drbrambedkarcollegeoflaw.com/pdf/admission-application-KSLU.pdf" target="_blank" className="text-white" rel="noopener noreferrer">
                            <div className="d-flex download mt-1">
                         <div className="ps-2 p-1"><Typography variant="body2"><b>Admission Application KSLU</b></Typography></div>
                         <div className="ms-auto bg-dark"><PlayArrowIcon/></div>
                        </div>
                        </a> 

<a href="https://pdf.drbrambedkarcollegeoflaw.com/pdf/prospectus.pdf" target="_blank" className="text-white" rel="noopener noreferrer">
                            <div className="d-flex download mt-1">
                         <div className="ps-2 p-1"><Typography variant="body2"><b>Prospectus</b></Typography></div>
                         <div className="ms-auto bg-dark"><PlayArrowIcon/></div>
                        </div>
                        </a>  

<a href="http://kslu.ac.in/question_paper.php" target="_blank" className="text-white" rel="noopener noreferrer">
                            <div className="d-flex download mt-1">
                         <div className="ps-2 p-1"><Typography variant="body2"><b>Previous Question Paper</b></Typography></div>
                         <div className="ms-auto bg-dark"><PlayArrowIcon/></div>
                        </div>
                        </a>   

  <a href="https://pdf.drbrambedkarcollegeoflaw.com/pdf/ABRAGAM_LINGAN_LTR.pdf" target="_blank" className="text-white" rel="noopener noreferrer">
                            <div className="d-flex download mt-1">
                         <div className="ps-2 p-1"><Typography variant="body2"><b>Abraham Lincoln Letter-English</b></Typography></div>
                         <div className="ms-auto bg-dark"><PlayArrowIcon/></div>
                        </div>
                        </a>     

<a href="https://pdf.drbrambedkarcollegeoflaw.com/pdf/letter-tamil.pdf" target="_blank" className="text-white" rel="noopener noreferrer">
                            <div className="d-flex download mt-1">
                         <div className="ps-2 p-1"><Typography variant="body2"><b>Abraham Lincoln Letter-Tamil</b></Typography></div>
                         <div className="ms-auto bg-dark"><PlayArrowIcon/></div>
                        </div>
                        </a>    

                        <Typography variant="h5" className="text-center bg-success mt-2 p-2 rounded-pill">
                <a href="https://api.whatsapp.com/send/?phone=7829956616&text&app_absent=0" target="_blank"  rel="noopener noreferrer" className="text-white"><WhatsAppIcon/> 7829956616</a>
              </Typography>           

  
</div>




            );
        }
      }