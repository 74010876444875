import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Box, ListItem, Typography, List } from '@material-ui/core';

import { StaticImage } from "gatsby-plugin-image"

import Download from "../components/download"

import { Container, Row, Col, Card } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';


import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const About = () => (
  <Layout>
    <Seo title="About College" description="About College, History"/>
    <ThemeProvider theme={theme}>
<Box style={{ position:'relative', bottom:40 }}>
<Container>
    <Row>
        <Col xs={12} lg={8} className="bg-white">
        <div className="p-3 list-icon mt-4">
           <Typography variant="h4" style={{ color:'#453260' }} gutterBottom>ABOUT COLLEGE</Typography>
           <Typography variant="h5" style={{ color:'#453260' }} gutterBottom>World humanitarian Charitable Trust</Typography>
           <div className="text-center pt-3 pb-3">
           <StaticImage
      src="../images/about.jpg"
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="ABOUT COLLEGE"
      class="border p-2 shadow-sm bg-light"
    />
        </div>
           <Typography variant="body2" paragraph>
            World Humanitarian Charitable Trust(R), a non-profitable organization, founded by Prof. C. Ramamurthy, an educationalist and philanthropist,aiming prominently to help the poor and the down- trodden people through education without discrimination of caste, creed color etc.,
           </Typography>
           <div classNam="list-icon">
           <List className="ps-1">
            <ListItem> The Trust emphasizes the candidates belonging to the SC, ST and linguistic minorities shall be given priority in all associated organizations including orphanages, slums and homes for the destitute.</ListItem>
            <ListItem>Providing all help during natural calamities major relief works including animal care.</ListItem>
            <ListItem> Establishing water sources, roads and bridges.</ListItem>
            <ListItem> Maintaining and running all of medical care outlets.</ListItem>
            <ListItem> Promoting art and culture through kala kendras.</ListItem>
            <ListItem> Protecting women and children through welfare centers.</ListItem>
            <ListItem> Providing scholarships, stipends and other monetary assistance to promote all types of educational activities.</ListItem>
            <ListItem> Aiding and running all organizations related to welfare measures that promote public good. Including trans-national, cultural, historical, remains.</ListItem>
            <ListItem> Assisting the publication (publishing) field and other media promote education and culture.</ListItem>
           </List>
           </div>

           <Typography variant="h5" gutterBottom>A Few Words about Founder- Chairman</Typography>
           <Typography variant="body2" paragraph>
            Man is the supreme creation of GOD. He has created in different personifications of power self and pursuits of perfect humanity. Today man is in pursuit of knowledge for the attainment of goals and to touch horizons of creativity and personal glory. In the direction, the need of a torch bearer and towering force of inspiration is of paramount importance to step up the ladder of achievement and success,. As quoted by John Keats, “unheard melodies are sweeter that the heard ones”. That melody and inspiration and confidence is none other Prof. C. Ramamurthy, the Founder- Chairman of Dr. B. Ambedkar College of Law, an university in himself with everlasting zest of dexterity in the field of education.
           </Typography>
           <Typography variant="body2" paragraph className="text-end text-danger">
            Swami Rathanananda,Valasarvakkam,Chennai.
           </Typography>
           
        </div>
        </Col>
        <Col xs={12} lg={4} className="bg-white">
        <Card className="shadow mb-2 bg-light mt-5">
  <Card.Body>
<Download/>
</Card.Body>
</Card>
 
</Col>

    </Row>
</Container>
</Box>

</ThemeProvider>
  </Layout>
)

export default About
